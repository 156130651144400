import React from 'react'

import Layout from '../../../components/layout'
import Link from 'gatsby-link';

import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'

import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import acomLogo from './ancestry-logo.svg'

import flowmapperExpress from './flowmapper-express.png'
import flowmapperCsat from './flowmapper-customersat.png'
import flowmapperAll from './flowmapper-all.png'
import flowmapperScreens from './flowmapper-screens.png'
import flowmapperToolTime from './flowmapper-tool-time.png'
import styled from '@emotion/styled'

const RED = styled.span`
  color: #c00;
`

const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`

const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`

export default () => (
  <Layout>
  <PageTransition>
  <BodyClassName className="">
  <div className="page">

  <article className="">

  <section className="contentSection">
    <div className="row">
      <div className="col-xs-9">
      <header>
        <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>
        <H1>Creating Ancestry's Journey Map Repository</H1>
      </header>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-7">
        <H4>The Problem</H4>
        <p className="introText">
          Ancestry’s web product has grown organically over a more than a decade and has many pieces connected in various ways. 
          New leaders without genealogy background had trouble learning how the product worked and were not able to experience parts of the product first hand. Development teams often did not understand how pages they might modify affected other parts of the experience.
        </p>
        

  <H4>Solution</H4>
       <p className="introText">
          When I took on the role of Director of User Experience at Ancestry, I quickly found that I needed to find a better way to understand and communicate in detail the customer journey to my peers and design team.
        </p>
        
      
      <p className="introText">
      Building a journey map repository provided a way to view the big picture and help everyone understand the customer journey more quickly.
        </p>
     

      </div>

      <div className="col-xs-12 col-sm-5">
        <ScrollAnimation animateIn="fadeIn" duration={.5}>
          <p><img src={acomLogo} style={{"maxWidth":"300px"}} alt=""/></p>          
          <ul>
            <li>Coordinated with Marketing, Customer Experience, and Product departments to deliver journey maps in a usable format.</li>    
            <li>Directed user research teams to identify and document customer sentiment on maps.</li>        
            <li>Integrated Adobe Analytics data on popoular flows to provide better context.</li>        
            <li>Developed a system to dynamically document and map customer journey.</li>            
            <li>Audited over 50 customer flows in the product on desktop and mobile platforms.</li>
            <li>Implemented Tool-Time metric to help designers ensure they were building designs that made our interfaces faster and less cumbersome.</li>
          </ul>
        </ScrollAnimation>
      </div>

    </div>

    </section>
    <section className="contentSection">
  <h2 className="metalab-heading">The Flowmapper Tool</h2>
    <div className="row">
      <div className="col-xs-8">
      <p>We developed a tool to help organize the process of mapping all of Ancestry's flows. This tool had the following features.</p>
      <ul>
      <li>Flowmapper provides complete and detailed documentation of all customer paths through our product. We have currently documented over 50 critical customer flows in this format.</li>
<li>Provides an excellent way to record customer satisfaction and notes and to share with teams.</li>
<li>Tool time calculator - natural language describing interactions is converted automatically to calculate estimated time based on the keystroke level modeling technique. Provides a quantitative metric that can be used to improve critical flows in the product.</li>
<li>When available, can display analytic data such as page views and time spent on the page to generate success metrics for various flows.</li>
</ul>

      </div>

    <div className="col-xs-4">
      <ScrollAnimation animateIn="fadeIn" duration={.5}>
      <img alt="" className="full" src={flowmapperExpress} />
      </ScrollAnimation>
    </div>
  </div>

    </section>


    <div className="mt-40" style={{"background":"#efefef"}}>
      <section className="contentSection">
  <h2 className="metalab-heading"><span className="numbers">1</span> Every Screen Mapped in Detail</h2>
      <div className="row">
        <div className="col-xs-12">
        <p>It is a surprising fact, at how little employees understand the reality of what customers experience in their product. This lack of understanding occurs due to lack of training, a mismatch in interests (employees vs. customers) and sometimes because we're interested in making new things more than we are in understanding and repairing what already exists.</p>
        <p>The process of capturing in detail every aspect of the customer journey helps you understand how the product works, and it makes problems visible in a horrifying, and useful way. Seeing screens next to each other that don't match, and being able to visualize where customers are stuck helps build empathy.</p>
        </div>
      </div>

      <div className="row">
      <div className="col-xs-12">
        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt="" className="full" src={flowmapperScreens} />
        </ScrollAnimation>
      </div>
</div>

      </section>
    </div>


      <section className="contentSection">
  <h2 className="metalab-heading"><span className="numbers">2</span> Document Customer Satisfaction for Each Step</h2>
      <div className="row">
      <div className="col-xs-12">
      <p>Information from customer research, analytics, and customer observation sessions are used to indicate customer satisfaction for each step.</p>
      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <img alt="" src={flowmapperCsat}/>
      </ScrollAnimation>


      </div>
      <div className="col-xs-12">

      </div>
      </div>
      </section>

        <div className="mt-40" style={{"background":"#efefef"}}>
            <section className="contentSection">
        <h2 className="metalab-heading"><span className="numbers">3</span> Tracking Tool Time</h2>
            <div className="row">
            <div className="col-xs-12">
            <p>It's easy to focus on visual layout and gloss over the detailed interactions that customers go through when using your application. A critical step in mapping the journey is to document the smallest specific interactions. By doing this, we were able to create objective metrics that we could use to show how we were improving the experience for our customers.</p>
            <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
              <img alt=""  className="full" src={flowmapperToolTime}/>
            </ScrollAnimation>


            </div>
            <div className="col-xs-12">

            </div>
            </div>
            </section>
            </div>

            <section className="contentSection">
        <h2 className="metalab-heading"><span className="numbers">4</span>Map Everything</h2>
            <div className="row">
            <div className="col-xs-12">
            <p>Flowmapper is an indispensable tool for understanding Ancestry’s product and how different pages work together. It is referenced daily and has been used successfully used to realize improvements in designs. It has also been a valuable tool to help onboard and teach new employees how the product works, and how elements are connected.  We are looking at expanding the tool and integrating with analytic data to help us understand and evaluate user behavior in the application.  </p>



            <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
              <img alt=""  className="full" src={flowmapperAll}/>
            </ScrollAnimation>
            <h2>Additional Thoughts</h2>
<p>Flowmapper as a tool has been very useful, but more importantly,  it influences and informs the design process.</p>

<p>When designers understand how the micro-interactions on each page affect customer satisfaction and can see how things they are designing lead to success, they are better able to create products with the customer in mind. </p>

<p> Designers often overlook micro-interactions and the effect their design has on the customer. FlowMapper has helped make that visible and often makes it very clear to the designers where there are opportunities to simplify or improve a process.</p>

            </div>
            <div className="col-xs-12">

            </div>
            </div>
            </section>

    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout>

)
